<!-- =========================================================================================
    File Name: Home.vue
    Description: Home
========================================================================================== -->

<template>
  <div>
    <vs-row vs-type="flex" vs-justify="center" class="mb-base">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
        <vs-button
          color="success"
          icon-pack="feather"
          icon="icon-log-in"
          size="large"
          @click="$router.push('/industry')"
          >{{
            $t(resources.AccessToPlatforms.i18n) ||
              resources.AccessToPlatforms.i18n
          }}</vs-button
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
        <vs-button
          type="gradient"
          color="primary"
          icon-pack="material-icons"
          icon="notifications_active"
          size="large"
          :href="`https://t.me/VisionTravelbot?start=${itaCode}`"
          >Telegram</vs-button
        >
      </vs-col>
    </vs-row>

    <div v-if="periods.length > 0">
      <div>
        <h4 class="text-center text-dark font-semibold mb-2">
          <feather-icon
            icon="CalendarIcon"
            svgClasses="h-8 w-8 mb-1 stroke-current text-success"
          />&nbsp;
          <strong class="font-semibold text-success">
            {{
              $t(resources.CurrentPeriod.i18n) || resources.CurrentPeriod.name
            }}:
          </strong>
          {{ currentPeriodLabel }}
        </h4>

        <p
          class="font-semibold text-center text-warning mb-base"
          v-if="itaStatus == 1 || itaStatus == 0"
        >
          <vs-icon
            icon-pack="feather"
            icon="icon-alert-triangle"
            size="small"
            color="rgba(var(--vs-warning), 1)"
          ></vs-icon>
          {{
            $t(resources.ExpirationDate.i18n) || resources.ExpirationDate.name
          }}: {{ userInfo.renewalDate }} |
          {{
            $t(resources.AffiliationDate.i18n) ||
              resources.AffiliationDate.name
          }}: {{ userInfo.affiliationDate }}
        </p>
        <div v-if="contactType == database.contactType.Ita">
          <div class="vx-row">
            <div class="vx-col w-full md:w-1/2">
              <div class="vx-row">
                <div class="vx-col w-full md:w-1/3 mb-base">
                  <vx-card class="text-center cursor-pointer">
                    <feather-icon
                      icon="UsersIcon"
                      svgClasses="h-6 w-6"
                      class="text-primary"
                    />
                    <h4 class="mb-2">
                      <strong>{{ newClient }}</strong>
                    </h4>
                    <small>
                      {{
                        $t(resources.NewCustomers90.i18n) ||
                          resources.NewCustomers90.name
                      }}
                    </small>
                  </vx-card>
                </div>
                <div class="vx-col w-full md:w-1/3 mb-base">
                  <vx-card class="text-center cursor-pointer">
                    <feather-icon
                      icon="ShoppingCartIcon"
                      svgClasses="h-6 w-6"
                      class="text-primary"
                    />
                    <h4 class="mb-2">
                      <strong>{{ last90days }}</strong>
                    </h4>
                    <small>
                      {{
                        $t(resources.DirectSales90.i18n) ||
                          resources.DirectSales90.name
                      }}
                    </small>
                  </vx-card>
                </div>
                <div class="vx-col w-full md:w-1/3 mb-base">
                  <vx-card class="text-center cursor-pointer">
                    <feather-icon
                      icon="CalendarIcon"
                      svgClasses="h-6 w-6"
                      class="text-primary"
                    />
                    <h4 class="mb-2">
                      <strong>{{ lastYear }}</strong>
                    </h4>
                    <small>
                      {{
                        $t(resources.DirectSales365.i18n) ||
                          resources.DirectSales365.name
                      }}
                    </small>
                  </vx-card>
                </div>
              </div>
              <!-- Volumens by business center -->
              <volumen
                :ita-code="itaCode"
                :period="currentPeriod"
                v-if="currentPeriod != ''"
              ></volumen>
            </div>

            <div class="vx-col w-full md:w-1/2" v-if="enabledHonors">
              <honors :honorsList="honorsList"></honors>
            </div>

            <!--Charts Volumens -->
            <!-- <div class="vx-row"> -->
            <div class="vx-col w-full md:w-1/2 mb-base">
              <vx-card
                :title="
                  ($t(resources.Volumen.i18n) || resources.Volumen.name) +
                    ' - ' +
                    ($t(resources.Center.i18n) || resources.Center.name) +
                    ' 001'
                "
                class="mb-base"
              >
                <div class="mt-1">
                  <vue-apex-charts
                    type="bar"
                    height="350"
                    :options="columnChart1.chartOptions"
                    :series="columnChart1.series"
                  ></vue-apex-charts>
                </div>
              </vx-card>
            </div>
            <div class="vx-col w-full md:w-1/2 mb-base">
              <vx-card
                :title="
                  $t(resources.VolumenPersonal.i18n) ||
                    resources.VolumenPersonal.name
                "
                class="mb-base"
              >
                <div class="mt-5">
                  <vue-apex-charts
                    type="bar"
                    height="350"
                    :options="columnChart2.chartOptions"
                    :series="columnChart2.series"
                  ></vue-apex-charts>
                </div>
              </vx-card>
            </div>

            <!-- Qualifications -->
            <div class="vx-col w-full md:w-1/2">
              <qualification
                :ita-code="itaCode"
                :period="currentPeriod"
                v-if="currentPeriod != ''"
              ></qualification>
            </div>
          </div>
        </div>

        <!-- DISPATCHED ORDERS -->
        <div class="vx-row">
          <div class="vx-col w-full mb-base">
            <vx-card
              :title="
                $t(resources.Last5Periods.i18n) || resources.Last5Periods.name
              "
            >
              <div slot="no-body" class="mt-4">
                <vs-table :data="orders">
                  <template slot="thead">
                    <vs-th>
                      {{ $t(resources.Number.i18n) || resources.Number.name }}
                    </vs-th>
                    <vs-th>
                      {{
                        $t(resources.PVAmount.i18n) || resources.PVAmount.name
                      }}
                    </vs-th>
                    <vs-th>
                      {{ $t(resources.Period.i18n) || resources.Period.name }}
                    </vs-th>
                    <vs-th width="8%">
                      {{
                        $t(resources.DistCountryCode.i18n) ||
                          resources.DistCountryCode.name
                      }}
                    </vs-th>
                    <vs-th width="8%">
                      {{
                        $t(resources.ShipToCountry.i18n) ||
                          resources.ShipToCountry.name
                      }}
                    </vs-th>
                    <vs-th>
                      {{
                        $t(resources.ShipToName.i18n) ||
                          resources.ShipToName.name
                      }}
                    </vs-th>
                    <vs-th>
                      {{ $t(resources.Date.i18n) || resources.Date.name }}
                    </vs-th>
                    <vs-th>
                      {{ $t(resources.Options.i18n) || resources.Options.name }}
                    </vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr
                      :key="indextr"
                      v-for="(tr, indextr) in data.ROWCOUNT"
                      v-on:click="getInvoice(data.DATA.ORDER_NUMBER[indextr])"
                    >
                      <vs-td :data="data.DATA.ORDER_NUMBER[indextr]">
                        <span>#{{ data.DATA.ORDER_NUMBER[indextr] }}</span>
                      </vs-td>
                      <vs-td :data="data.DATA.PV_AMOUNT[indextr]">
                        <span>{{ data.DATA.PV_AMOUNT[indextr] }}</span>
                      </vs-td>
                      <vs-td :data="data.DATA.PV_YEAR[indextr]">
                        <span>
                          {{
                            data.DATA.PV_YEAR[indextr] +
                              "/" +
                              data.DATA.PV_PERIOD[indextr]
                          }}
                        </span>
                      </vs-td>
                      <vs-td :data="data.DATA.DST_COUNTRY_CODE[indextr]">
                        <span>{{ data.DATA.DST_COUNTRY_CODE[indextr] }}</span>
                      </vs-td>
                      <vs-td :data="data.DATA.SHIP_TO_COUNTRY[indextr]">
                        <span>{{ data.DATA.SHIP_TO_COUNTRY[indextr] }}</span>
                      </vs-td>
                      <vs-td :data="data.DATA.SHIP_TO_NAME[indextr]">
                        <span>{{ data.DATA.SHIP_TO_NAME[indextr] }}</span>
                      </vs-td>
                      <vs-td :data="data.DATA.ENTRY_DATE[indextr]">
                        <span>{{ data.DATA.ENTRY_DATE[indextr] }}</span>
                      </vs-td>
                      <vs-td>
                        <vx-tooltip
                          :text="$t(resources.View.i18n) || resources.View.name"
                        >
                          <a
                            @click="getInvoice(data.DATA.ORDER_NUMBER[indextr])"
                          >
                            <feather-icon
                              icon="EyeIcon"
                              class="w-5 h-5 mr-1"
                            ></feather-icon>
                          </a>
                        </vx-tooltip>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </vx-card>
          </div>
        </div>

        <!-- POPUP -->
        <div class="demo-alignment">
          <vs-popup
            fullscreen
            :title="$t(resources.Invoice.i18n) || resources.Invoice.name"
            :active.sync="popupActive"
          >
            <div id="invoice-page">
              <!-- INVOICE METADATA -->
              <div class="vx-row leading-loose p-base">
                <div class="vx-col w-full md:w-1/2 mt-base">
                  <img
                    src="@/assets/images/logo/logo_vt.png"
                    alt="vuesax-logo"
                  />
                </div>
                <div class="vx-col w-full md:w-1/2 text-right">
                  <h1>
                    {{ $t(resources.Invoice.i18n) || resources.Invoice.name }}
                  </h1>
                  <div class="invoice__invoice-detail mt-6">
                    <h6>
                      {{
                        $t(resources.OrderNumber.i18n) || resources.Invoice.name
                      }}
                    </h6>
                    <p>{{ invoice.odh_number }}</p>

                    <h6 class="mt-4">
                      {{
                        $t(resources.OrderDate.i18n) || resources.OrderDate.name
                      }}
                    </h6>
                    <p>{{ invoice.ord_ent_dt }}{{ invoice.ord_ent_tm }}</p>
                  </div>
                </div>
                <div class="vx-col w-full md:w-1/2 mt-12">
                  <h5>
                    <strong>
                      {{ $t(resources.BillTo.i18n) || resources.BillTo.name }}:
                    </strong>
                  </h5>
                  <div class="invoice__recipient-info my-4">
                    <p>{{ invoice.DST_Display_Name_1 }}</p>
                    <p>{{ invoice.DST_Addr }}</p>
                    <p>{{ invoice.addressLine2 }}</p>
                    <p>{{ invoice.DST_Addr_3 }}</p>
                  </div>
                  <div class="invoice__recipient-contact">
                    <p class="flex items-center">
                      <feather-icon
                        icon="MailIcon"
                        svgClasses="h-4 w-4"
                      ></feather-icon>
                      <span class="ml-2">{{ invoice.DST_email_addr }}</span>
                    </p>
                    <p class="flex items-center">
                      <feather-icon
                        icon="PhoneIcon"
                        svgClasses="h-4 w-4"
                      ></feather-icon>
                      <span class="ml-2">{{ invoice.DST_Home_Phone }}</span>
                    </p>
                  </div>
                </div>
                <div class="vx-col w-full md:w-1/2 mt-base text-right mt-12">
                  <h5>
                    <strong>
                      {{ $t(resources.ShipTo.i18n) || resources.ShipTo.name }}:
                    </strong>
                  </h5>
                  <h5>{{ invoice.ship_to_name }}</h5>
                  <div class="invoice__company-info my-4">
                    <p>{{ invoice.ship_to_addr_1 }}</p>
                    <p>{{ invoice.ship_to_addr_3 }}</p>
                    <p>
                      {{ invoice.ship_to_city
                      }}{{ " (Postal code:" + invoice.ship_to_zip + ")" }}
                    </p>
                    <p></p>
                  </div>
                  <div class="invoice__company-contact">
                    <p class="flex items-center justify-end">
                      <feather-icon
                        icon="MailIcon"
                        svgClasses="h-4 w-4"
                      ></feather-icon>
                      <span class="ml-2">{{ invoice.ship_to_email }}</span>
                    </p>
                    <p class="flex items-center justify-end">
                      <feather-icon
                        icon="PhoneIcon"
                        svgClasses="h-4 w-4"
                      ></feather-icon>
                      <span class="ml-2">{{ invoice.ship_to_phone }}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div v-if="!invoice">
                {{
                  $t(resources.NoAvailableData.i18n) ||
                    resources.NoAvailableData.name
                }}
              </div>
              <div v-else class="p-base">
                <vs-table hoverFlat :data="invoice">
                  <template slot="thead">
                    <vs-th>
                      {{ $t(resources.Item.i18n) || resources.Item.name }}
                    </vs-th>
                    <vs-th>
                      {{ $t(resources.Code.i18n) || resources.Code.name }}
                    </vs-th>
                    <vs-th>
                      {{ $t(resources.QtyShip.i18n) || resources.QtyShip.name }}
                    </vs-th>
                    <vs-th>
                      {{
                        $t(resources.QtyOrder.i18n) || resources.QtyOrder.name
                      }}
                    </vs-th>
                    <vs-th>
                      {{
                        $t(resources.Description.i18n) ||
                          resources.Description.name
                      }}
                    </vs-th>
                    <vs-th>
                      {{ $t(resources.Volumen.i18n) || resources.Volumen.name }}
                    </vs-th>
                    <vs-th>
                      {{
                        $t(resources.TotalVolumen.i18n) ||
                          resources.TotalVolumen.name
                      }}
                    </vs-th>
                    <vs-th>
                      {{ $t(resources.Price.i18n) || resources.Price.name }}
                    </vs-th>
                    <vs-th>
                      {{ $t(resources.Total.i18n) || resources.Total.name }}
                    </vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data.odl">
                      <vs-td>{{ indextr + 1 }}</vs-td>
                      <vs-td :data="data.odl[indextr].item_code">
                        {{ data.odl[indextr].item_code }}
                      </vs-td>
                      <vs-td :data="data.odl[indextr].qty_shipped">
                        {{ data.odl[indextr].qty_shipped }}
                      </vs-td>
                      <vs-td :data="data.odl[indextr].qty_ordered">
                        {{ data.odl[indextr].qty_ordered }}
                      </vs-td>
                      <vs-td :data="data.odl[indextr].item_name_1">
                        {{ data.odl[indextr].item_name_1 }}
                      </vs-td>
                      <vs-td :data="data.odl[indextr].price_2">
                        {{ data.odl[indextr].price_2 }}
                      </vs-td>
                      <vs-td :data="data.pv_amount">{{ data.pv_amount }}</vs-td>
                      <vs-td :data="data.odl[indextr].sales_price">
                        {{ data.odl[indextr].sales_price }}
                      </vs-td>
                      <vs-td :data="data.sales_amount">
                        {{ data.sales_amount }}
                      </vs-td>
                    </vs-tr>
                    <vs-tr>
                      <vs-td></vs-td>
                      <vs-td></vs-td>
                      <vs-td></vs-td>
                      <vs-td></vs-td>
                      <vs-td></vs-td>
                      <th>
                        {{
                          $t(resources.TotalVolumen.i18n) ||
                            resources.TotalVolumen.name
                        }}
                      </th>
                      <td>{{ invoice.pv_amount }}</td>
                      <th>
                        {{
                          $t(resources.Subtotal.i18n) || resources.Subtotal.name
                        }}
                      </th>
                      <td>{{ invoice.sales_amount }}</td>
                    </vs-tr>
                  </template>
                </vs-table>

                <div class="vx-row">
                  <div class="vx-col w-full lg:w-1/2 mb-base p-base">
                    <h6>
                      {{
                        $t(resources.Comments.i18n) || resources.Comments.name
                      }}:
                    </h6>
                    <p>{{ invoice.comment_1 }}</p>
                    <p>{{ invoice.comment_2 }}</p>
                    <p>{{ invoice.comment_3 }}</p>
                    <p>{{ invoice.comment_4 }}</p>
                    <p>{{ invoice.comment_5 }}</p>
                    <p>{{ invoice.comment_6 }}</p>
                    <br />
                    <h6>
                      <strong>Warehouse 2</strong>
                    </h6>
                    <p>
                      <strong>{{ invoice.ord_whs_nm1 }}</strong>
                    </p>
                    <p>{{ invoice.ord_whs_adr1 }}</p>
                    <p>{{ invoice.ord_whs_adr2 }}</p>
                    <p>{{ invoice.ord_whs_adr3 }}</p>
                  </div>
                  <div class="vx-col w-full lg:w-1/2 mb-base p-base">
                    <table hoverFlat class="w-3/4 ml-auto mt-3">
                      <vs-tr>
                        <th>
                          {{ $t(resources.Tax.i18n) || resources.Tax.name }} ({{
                            $t(resources.Taxable.i18n) || resources.Taxable.name
                          }}
                          0.00 @ {{ invoice.tax_percent }}%)
                        </th>
                        <td>{{ invoice.total_taxable_amount }}</td>
                      </vs-tr>
                      <vs-tr>
                        <th>
                          {{
                            $t(resources.ShippingAndHandling.i18n) ||
                              resources.ShippingAndHandling.name
                          }}
                          ({{ invoice.ord_shv_nm }})
                        </th>
                        <td>{{ invoice.total_invoice_amt }}</td>
                      </vs-tr>
                      <vs-tr>
                        <th>
                          {{
                            $t(resources.TotalAmountDue.i18n) ||
                              resources.TotalAmountDue.name
                          }}
                        </th>
                        <td>{{ invoice.sales_amount }}</td>
                      </vs-tr>
                    </table>
                  </div>
                </div>
              </div>

              <!-- INVOICE FOOTER -->
              <div class="invoice__footer text-right p-base">
                <h5>
                  <strong>
                    {{
                      $t(resources.PaymentsReceived.i18n) ||
                        resources.PaymentsReceived.name
                    }}
                  </strong>
                </h5>
                <p>
                  <strong>
                    {{
                      $t(resources.ReceiptAmount.i18n) ||
                        resources.ReceiptAmount.name
                    }}
                  </strong>
                  {{ invoice.amount_received }}
                </p>
                <p>
                  <strong>
                    {{
                      $t(resources.TotalCashReceipts.i18n) ||
                        resources.TotalCashReceipts.name
                    }}
                  </strong>
                  {{ invoice.sales_amount }}
                </p>
                <p>
                  <strong>
                    {{
                      $t(resources.InvoceBalance.i18n) ||
                        resources.InvoceBalance.name
                    }}
                  </strong>
                  {{ invoice.invoice_balance }}
                </p>
              </div>
              <div class="vx-row">
                <div class="vx-col w-full text-right">
                  <vs-button
                    color="rgb(174,174,174)"
                    type="filled"
                    @click="popupActive = false"
                  >
                    {{ $t(resources.Close.i18n) || resources.Close.name }}
                  </vs-button>
                </div>
              </div>
            </div>
          </vs-popup>
        </div>
      </div>
      <!-- <div v-else>
        <vx-card class="mb-4">
          <h4 class="text-center text-danger mb-2">
            {{
              $t(alerts.MsgNotAllowGenealogy.i18n) ||
                alerts.MsgNotAllowGenealogy.i18n
            }}.
          </h4>
          <h4 class="text-center text-dark font-bold mb-2">
            <feather-icon
              icon="CalendarIcon"
              svgClasses="h-8 w-8 mb-1 stroke-current text-danger"
            />
            <strong class="font-semibold text-danger">
              {{
                $t(resources.ExpirationDate.i18n) ||
                  resources.ExpirationDate.name
              }}:
            </strong>
            {{ renewal_date }}
          </h4>
          <h4 class="text-center text-dark font-semibold mb-2">
            <feather-icon
              icon="CalendarIcon"
              svgClasses="h-8 w-8 mb-1 stroke-current text-danger"
            />&nbsp;
            <strong class="font-semibold text-danger">
              {{
                $t(resources.CurrentPeriod.i18n) ||
                  resources.CurrentPeriod.name
              }}:
            </strong>
            {{ currentPeriodLabel }}
          </h4>
        </vx-card>

        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2" v-if="enabledHonors">
            <honors :honorsList="honorsList"></honors>
          </div>
        </div>
      </div> -->
    </div>
    <div v-else>
      <vx-card>
        <h4 class="text-center text-danger mb-2">
          {{
            $t(alerts.MsgInfotraxOffline.i18n) ||
              alerts.MsgInfotraxOffline.i18n
          }}.
        </h4>
      </vx-card>
    </div>
    <vs-popup
      :title="$t(resources.News.i18n) || resources.News.i18n"
      :active.sync="enabledPopup"
    >
      <a :href="urlPopup">
        <img :src="imgPopup" alt="graphic" width="550" heigth="500" />
      </a>

      <!-- <iframe
        width="560"
        height="350"
        src="https://www.youtube.com/embed/g31vWN9kcAs"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>-->
    </vs-popup>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import axios from "axios";
import jspdf from "jspdf";
import logo_base64 from "@/assets/images/logo/logo_base64.js";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import vSelect from "vue-select";
import Qualification from "./general/qualification.vue";
import Volumen from "./general/volumen.vue";
import authVT from "@/assets/resources/auth.json";
import database from "@/assets/resources/enums/database.json";
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import honors from "./general/honors.vue";

export default {
  data() {
    return {
      authVT: authVT,
      resources: resources,
      alerts: alerts,
      database: database,
      nroCenters: 0,
      newClient: "",
      last90days: "",
      lastYear: "",
      itaStatus: localStorage.getItem("status"),
      popupVideo: true,
      enabledHonors: false,
      honorsList: [],
      columnChart1: {
        series: [
          {
            name: this.$i18n.t("Left"),
            data: [0]
          },
          {
            name: this.$i18n.t("Right"),
            data: [0]
          }
        ],
        chartOptions: {
          chart: {
            id: "chart1"
          },
          colors: ["#28C76F", "#FF9F43"],
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: "rounded",
              columnWidth: "50%"
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          xaxis: {
            categories: [this.$i18n.t("LastWeek"), this.$i18n.t("Currently")]
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return val;
              }
            }
          }
        }
      },
      columnChart2: {
        series: [
          {
            name: this.$i18n.t("Amount"),
            data: [0]
          }
        ],
        chartOptions: {
          chart: {
            id: "chart2"
          },
          colors: ["#FFBD33", "#D43743"],
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: "flat",
              columnWidth: "50%"
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          xaxis: {
            categories: [this.$i18n.t("LastWeek"), this.$i18n.t("Currently")]
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return val;
              }
            }
          }
        }
      },
      name: JSON.parse(localStorage.getItem("userInfo")).name,
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode.toString(),
      periods: JSON.parse(localStorage.getItem("periods")),
      labels: JSON.parse(localStorage.getItem("labels")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      orders: "",
      popupActive: false,
      invoice: "",
      isImp: false,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 5,
      imgLogo: logo_base64.image,
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      renewal_date: JSON.parse(localStorage.getItem("userInfo")).renewalDate,
      contactType: JSON.parse(localStorage.getItem("userInfo")).contactType,
      urlPopup: "",
      imgPopup: "",
      enabledPopup: false
    };
  },
  created: async function() {
    //    this.$vs.loading();

    await this.getParams();
    if (this.periods.length > 0) {
      const date = (this.renewal_date = JSON.parse(
        localStorage.getItem("userInfo")
      ).renewalDate);

      if (date == null && this.itaStatus == 2) await this.validate();
      else {
        this.renewal_date = JSON.parse(
          localStorage.getItem("userInfo")
        ).renewalDate;
        await this.listOrders();

        if (this.contactType == this.database.contactType.Ita)
          await this.getSummary();
      }
    }

    //this.$vs.loading.close();
  },
  computed: {
    //Current period label
    currentPeriodLabel() {
      return this.labels[0];
    },
    currentPeriod() {
      return this.periods[0];
    }
  },
  methods: {
    download(orderId) {
      var doc = new jspdf();

      var img = this.imgLogo;
      //this.getInvoice(orderId);
      let pdfName = "#" + orderId + ".pdf";

      doc.addImage(img, "png", 7, 10, 110, 30, undefined, "none");

      doc.text("Hello, my name is ana", 20, 50);
      doc.save(pdfName);
    },

    //Get invoice of the order
    async getInvoice(orderId) {
      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "getInvoice",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
          odhnumber: orderId
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          this.invoice = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.popupActive = true;
    },

    //get Points by ITA.
    async getSummary() {
      let jsonLast;
      let jsonCurrent;

      for (var i = 0; i < 2; i++) {
        await axios({
          method: "POST",
          url: `${process.env.VUE_APP_OVAPI}getSummary`,
          data: {
            infotoken: localStorage.getItem("tokenInfo"),
            distid: this.itaCode,
            period: this.periods[i]
          },
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + localStorage.getItem("loggedIn")
          }
        }).then(
          result => {
            if (i == 0) jsonCurrent = result.data.FIELDS;
            else jsonLast = result.data.FIELDS;
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
      this.readPV(jsonLast, jsonCurrent);
    },

    //Get list orders by ITA.
    async listOrders() {
      var firstPeriod = this.periods[this.periods.length - 1];
      var lastPeriod = this.periods[0];

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}ListOrders`,
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
          start_pvdate: firstPeriod,
          end_pvdate: lastPeriod,
          fields:
            "ORDER_NUMBER,PV_AMOUNT,PV_YEAR,PV_PERIOD,DST_COUNTRY_CODE,SHIP_TO_COUNTRY,SHIP_TO_NAME,ENTRY_DATE"
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          this.orders = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    //Read Points Value and set in the chart.
    readPV(jsonLast, jsonCurrent) {
      //New clients the last 90 days
      this.newClient = jsonCurrent.find(
        jsonCurrent => jsonCurrent.BQF_Field_Name === "WRK42"
      ).BQF_Field_Value;

      //Sales the last 90 days
      this.last90days = jsonCurrent.find(
        jsonCurrent => jsonCurrent.BQF_Field_Name === "WRK43"
      ).BQF_Field_Value;

      //Sales the last 365 days
      this.lastYear = jsonCurrent.find(
        jsonCurrent => jsonCurrent.BQF_Field_Name === "WRK44"
      ).BQF_Field_Value;

      //Number business centers
      this.nroCenters = jsonCurrent.find(
        jsonCurrent => jsonCurrent.BQF_Field_Name === "WRK10"
      ).BQF_Field_Value;
      localStorage.setItem("numberCenters", this.nroCenters);

      let volLeft = [];
      let volRight = [];
      let volPersonal = [];
      volLeft.push(
        jsonLast.find(x => x.BQF_Field_Name === "VOL4").BQF_Field_Value
      );
      volLeft.push(
        jsonCurrent.find(x => x.BQF_Field_Name === "VOL4").BQF_Field_Value
      );

      volRight.push(
        jsonLast.find(x => x.BQF_Field_Name === "VOL5").BQF_Field_Value
      );
      volRight.push(
        jsonCurrent.find(x => x.BQF_Field_Name === "VOL5").BQF_Field_Value
      );

      volPersonal.push(
        jsonLast.find(x => x.BQF_Field_Name === "VOL1").BQF_Field_Value
      );
      volPersonal.push(
        jsonCurrent.find(x => x.BQF_Field_Name === "VOL1").BQF_Field_Value
      );

      ApexCharts.exec("chart1", "updateSeries", [
        {
          name: this.$i18n.t("Left"),
          data: volLeft
        },
        {
          name: this.$i18n.t("Right"),
          data: volRight
        }
      ]);

      ApexCharts.exec("chart2", "updateSeries", [
        {
          data: volPersonal
        }
      ]);

      //New clients the last 90 days
      this.newClient = jsonCurrent.find(
        jsonCurrent => jsonCurrent.BQF_Field_Name === "WRK42"
      ).BQF_Field_Value;

      //Sales the last 90 days
      this.last90days = jsonCurrent.find(
        jsonCurrent => jsonCurrent.BQF_Field_Name === "WRK43"
      ).BQF_Field_Value;

      //Sales the last 365 days
      this.lastYear = jsonCurrent.find(
        jsonCurrent => jsonCurrent.BQF_Field_Name === "WRK44"
      ).BQF_Field_Value;
    },

    async validate() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}validate`,
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode
        },
        headers: {
          "content-type": "application/json"
        }
      }).then(
        result => {
          if (result.data) {
            this.renewal_date = result.data.Next_Renew_Date;
            localStorage.setItem("renewalDate", this.renewal_date);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getParams() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ParameterAplication/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        async result => {
          if (result.data.length > 0) {
            this.urlPopup = result.data.find(
              x => x.parameter.name == "PopupHomeUrl"
            ).value;
            this.imgPopup = result.data.find(
              x => x.parameter.name == "PopupHomeImage"
            ).value;
            this.enabledPopup =
              result.data
                .find(x => x.parameter.name == "PopupHomeEnabled")
                .value.toLowerCase() == "true"
                ? true
                : false;

            const honorsImage = result.data.find(
              x => x.parameter.name == "HonorsHomeImages"
            ).value;

            honorsImage.split(",").forEach(element => {
              this.honorsList.push(
                `https://cdn1.visiontravel.net/Images/home/${element}`
              );
            });

            this.enabledHonors =
              result.data
                .find(x => x.parameter.name == "HonorsHomeEnabled")
                .value.toLowerCase() == "true"
                ? true
                : false;
          } else {
            this.urlPopup = "";
            this.imgPopup = "";
            this.enabledPopup = false;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  components: {
    FormWizard,
    TabContent,
    VueApexCharts,
    "v-select": vSelect,
    Qualification,
    Volumen,
    swiper,
    swiperSlide,
    honors
  }
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>
