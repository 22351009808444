var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        {
          staticClass: "mb-2",
          attrs: {
            title:
              _vm.$t(_vm.resources.WeeksHonors.i18n) ||
              _vm.resources.WeeksHonors.name
          }
        },
        [
          _c(
            "div",
            { staticClass: "carousel-example" },
            [
              _c(
                "swiper",
                { attrs: { options: _vm.swiperOption } },
                [
                  _vm._l(_vm.honorsList, function(item) {
                    return _c("swiper-slide", { key: item }, [
                      _c("img", {
                        staticClass: "responsive",
                        attrs: { src: item, width: "550", heigth: "500" }
                      })
                    ])
                  }),
                  _c("div", {
                    staticClass: "swiper-button-prev swiper-button-white",
                    attrs: { slot: "button-prev" },
                    slot: "button-prev"
                  }),
                  _c("div", {
                    staticClass: "swiper-button-next swiper-button-white",
                    attrs: { slot: "button-next" },
                    slot: "button-next"
                  })
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }