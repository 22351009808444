var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        {
          staticClass: "mb-base",
          attrs: { "vs-type": "flex", "vs-justify": "center" }
        },
        [
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "2"
              }
            },
            [
              _c(
                "vs-button",
                {
                  attrs: {
                    color: "success",
                    "icon-pack": "feather",
                    icon: "icon-log-in",
                    size: "large"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/industry")
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.AccessToPlatforms.i18n) ||
                        _vm.resources.AccessToPlatforms.i18n
                    )
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "vs-col",
            {
              attrs: {
                "vs-type": "flex",
                "vs-justify": "center",
                "vs-align": "center",
                "vs-w": "3"
              }
            },
            [
              _c(
                "vs-button",
                {
                  attrs: {
                    type: "gradient",
                    color: "primary",
                    "icon-pack": "material-icons",
                    icon: "notifications_active",
                    size: "large",
                    href: "https://t.me/VisionTravelbot?start=" + _vm.itaCode
                  }
                },
                [_vm._v("Telegram")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.periods.length > 0
        ? _c("div", [
            _c("div", [
              _c(
                "h4",
                { staticClass: "text-center text-dark font-semibold mb-2" },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: "CalendarIcon",
                      svgClasses: "h-8 w-8 mb-1 stroke-current text-success"
                    }
                  }),
                  _vm._v(" \n        "),
                  _c("strong", { staticClass: "font-semibold text-success" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(_vm.resources.CurrentPeriod.i18n) ||
                            _vm.resources.CurrentPeriod.name
                        ) +
                        ":\n        "
                    )
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.currentPeriodLabel) + "\n      "
                  )
                ],
                1
              ),
              _vm.itaStatus == 1 || _vm.itaStatus == 0
                ? _c(
                    "p",
                    {
                      staticClass:
                        "font-semibold text-center text-warning mb-base"
                    },
                    [
                      _c("vs-icon", {
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-alert-triangle",
                          size: "small",
                          color: "rgba(var(--vs-warning), 1)"
                        }
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(_vm.resources.ExpirationDate.i18n) ||
                              _vm.resources.ExpirationDate.name
                          ) +
                          ": " +
                          _vm._s(_vm.userInfo.renewalDate) +
                          " |\n        " +
                          _vm._s(
                            _vm.$t(_vm.resources.AffiliationDate.i18n) ||
                              _vm.resources.AffiliationDate.name
                          ) +
                          ": " +
                          _vm._s(_vm.userInfo.affiliationDate) +
                          "\n      "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contactType == _vm.database.contactType.Ita
                ? _c("div", [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full md:w-1/2" },
                        [
                          _c("div", { staticClass: "vx-row" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full md:w-1/3 mb-base" },
                              [
                                _c(
                                  "vx-card",
                                  { staticClass: "text-center cursor-pointer" },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "text-primary",
                                      attrs: {
                                        icon: "UsersIcon",
                                        svgClasses: "h-6 w-6"
                                      }
                                    }),
                                    _c("h4", { staticClass: "mb-2" }, [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.newClient))
                                      ])
                                    ]),
                                    _c("small", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.NewCustomers90.i18n
                                            ) ||
                                              _vm.resources.NewCustomers90.name
                                          ) +
                                          "\n                  "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "vx-col w-full md:w-1/3 mb-base" },
                              [
                                _c(
                                  "vx-card",
                                  { staticClass: "text-center cursor-pointer" },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "text-primary",
                                      attrs: {
                                        icon: "ShoppingCartIcon",
                                        svgClasses: "h-6 w-6"
                                      }
                                    }),
                                    _c("h4", { staticClass: "mb-2" }, [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.last90days))
                                      ])
                                    ]),
                                    _c("small", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.DirectSales90.i18n
                                            ) ||
                                              _vm.resources.DirectSales90.name
                                          ) +
                                          "\n                  "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "vx-col w-full md:w-1/3 mb-base" },
                              [
                                _c(
                                  "vx-card",
                                  { staticClass: "text-center cursor-pointer" },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "text-primary",
                                      attrs: {
                                        icon: "CalendarIcon",
                                        svgClasses: "h-6 w-6"
                                      }
                                    }),
                                    _c("h4", { staticClass: "mb-2" }, [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.lastYear))
                                      ])
                                    ]),
                                    _c("small", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.DirectSales365.i18n
                                            ) ||
                                              _vm.resources.DirectSales365.name
                                          ) +
                                          "\n                  "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm.currentPeriod != ""
                            ? _c("volumen", {
                                attrs: {
                                  "ita-code": _vm.itaCode,
                                  period: _vm.currentPeriod
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.enabledHonors
                        ? _c(
                            "div",
                            { staticClass: "vx-col w-full md:w-1/2" },
                            [
                              _c("honors", {
                                attrs: { honorsList: _vm.honorsList }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "vx-col w-full md:w-1/2 mb-base" },
                        [
                          _c(
                            "vx-card",
                            {
                              staticClass: "mb-base",
                              attrs: {
                                title:
                                  (_vm.$t(_vm.resources.Volumen.i18n) ||
                                    _vm.resources.Volumen.name) +
                                  " - " +
                                  (_vm.$t(_vm.resources.Center.i18n) ||
                                    _vm.resources.Center.name) +
                                  " 001"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mt-1" },
                                [
                                  _c("vue-apex-charts", {
                                    attrs: {
                                      type: "bar",
                                      height: "350",
                                      options: _vm.columnChart1.chartOptions,
                                      series: _vm.columnChart1.series
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col w-full md:w-1/2 mb-base" },
                        [
                          _c(
                            "vx-card",
                            {
                              staticClass: "mb-base",
                              attrs: {
                                title:
                                  _vm.$t(_vm.resources.VolumenPersonal.i18n) ||
                                  _vm.resources.VolumenPersonal.name
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mt-5" },
                                [
                                  _c("vue-apex-charts", {
                                    attrs: {
                                      type: "bar",
                                      height: "350",
                                      options: _vm.columnChart2.chartOptions,
                                      series: _vm.columnChart2.series
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col w-full md:w-1/2" },
                        [
                          _vm.currentPeriod != ""
                            ? _c("qualification", {
                                attrs: {
                                  "ita-code": _vm.itaCode,
                                  period: _vm.currentPeriod
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full mb-base" },
                  [
                    _c(
                      "vx-card",
                      {
                        attrs: {
                          title:
                            _vm.$t(_vm.resources.Last5Periods.i18n) ||
                            _vm.resources.Last5Periods.name
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "mt-4",
                            attrs: { slot: "no-body" },
                            slot: "no-body"
                          },
                          [
                            _c(
                              "vs-table",
                              {
                                attrs: { data: _vm.orders },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var data = ref.data
                                        return _vm._l(data.ROWCOUNT, function(
                                          tr,
                                          indextr
                                        ) {
                                          return _c(
                                            "vs-tr",
                                            {
                                              key: indextr,
                                              on: {
                                                click: function($event) {
                                                  return _vm.getInvoice(
                                                    data.DATA.ORDER_NUMBER[
                                                      indextr
                                                    ]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data.DATA.ORDER_NUMBER[
                                                        indextr
                                                      ]
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "#" +
                                                        _vm._s(
                                                          data.DATA
                                                            .ORDER_NUMBER[
                                                            indextr
                                                          ]
                                                        )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data.DATA.PV_AMOUNT[
                                                        indextr
                                                      ]
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.DATA.PV_AMOUNT[
                                                          indextr
                                                        ]
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data.DATA.PV_YEAR[indextr]
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          data.DATA.PV_YEAR[
                                                            indextr
                                                          ] +
                                                            "/" +
                                                            data.DATA.PV_PERIOD[
                                                              indextr
                                                            ]
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data.DATA
                                                        .DST_COUNTRY_CODE[
                                                        indextr
                                                      ]
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.DATA
                                                          .DST_COUNTRY_CODE[
                                                          indextr
                                                        ]
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data.DATA.SHIP_TO_COUNTRY[
                                                        indextr
                                                      ]
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.DATA
                                                          .SHIP_TO_COUNTRY[
                                                          indextr
                                                        ]
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data.DATA.SHIP_TO_NAME[
                                                        indextr
                                                      ]
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.DATA.SHIP_TO_NAME[
                                                          indextr
                                                        ]
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data.DATA.ENTRY_DATE[
                                                        indextr
                                                      ]
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.DATA.ENTRY_DATE[
                                                          indextr
                                                        ]
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                [
                                                  _c(
                                                    "vx-tooltip",
                                                    {
                                                      attrs: {
                                                        text:
                                                          _vm.$t(
                                                            _vm.resources.View
                                                              .i18n
                                                          ) ||
                                                          _vm.resources.View
                                                            .name
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.getInvoice(
                                                                data.DATA
                                                                  .ORDER_NUMBER[
                                                                  indextr
                                                                ]
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("feather-icon", {
                                                            staticClass:
                                                              "w-5 h-5 mr-1",
                                                            attrs: {
                                                              icon: "EyeIcon"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        })
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  806655332
                                )
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "thead" },
                                  [
                                    _c("vs-th", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(_vm.resources.Number.i18n) ||
                                              _vm.resources.Number.name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.PVAmount.i18n
                                            ) || _vm.resources.PVAmount.name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(_vm.resources.Period.i18n) ||
                                              _vm.resources.Period.name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("vs-th", { attrs: { width: "8%" } }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.DistCountryCode.i18n
                                            ) ||
                                              _vm.resources.DistCountryCode.name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("vs-th", { attrs: { width: "8%" } }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.ShipToCountry.i18n
                                            ) ||
                                              _vm.resources.ShipToCountry.name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.ShipToName.i18n
                                            ) || _vm.resources.ShipToName.name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(_vm.resources.Date.i18n) ||
                                              _vm.resources.Date.name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.Options.i18n
                                            ) || _vm.resources.Options.name
                                          ) +
                                          "\n                  "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "demo-alignment" },
                [
                  _c(
                    "vs-popup",
                    {
                      attrs: {
                        fullscreen: "",
                        title:
                          _vm.$t(_vm.resources.Invoice.i18n) ||
                          _vm.resources.Invoice.name,
                        active: _vm.popupActive
                      },
                      on: {
                        "update:active": function($event) {
                          _vm.popupActive = $event
                        }
                      }
                    },
                    [
                      _c("div", { attrs: { id: "invoice-page" } }, [
                        _c(
                          "div",
                          { staticClass: "vx-row leading-loose p-base" },
                          [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full md:w-1/2 mt-base" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/logo/logo_vt.png"),
                                    alt: "vuesax-logo"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "vx-col w-full md:w-1/2 text-right"
                              },
                              [
                                _c("h1", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(_vm.resources.Invoice.i18n) ||
                                          _vm.resources.Invoice.name
                                      ) +
                                      "\n                "
                                  )
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "invoice__invoice-detail mt-6"
                                  },
                                  [
                                    _c("h6", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.OrderNumber.i18n
                                            ) || _vm.resources.Invoice.name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.invoice.odh_number))
                                    ]),
                                    _c("h6", { staticClass: "mt-4" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.OrderDate.i18n
                                            ) || _vm.resources.OrderDate.name
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.invoice.ord_ent_dt) +
                                          _vm._s(_vm.invoice.ord_ent_tm)
                                      )
                                    ])
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "vx-col w-full md:w-1/2 mt-12" },
                              [
                                _c("h5", [
                                  _c("strong", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t(_vm.resources.BillTo.i18n) ||
                                            _vm.resources.BillTo.name
                                        ) +
                                        ":\n                  "
                                    )
                                  ])
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "invoice__recipient-info my-4"
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.invoice.DST_Display_Name_1)
                                      )
                                    ]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.invoice.DST_Addr))
                                    ]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.invoice.addressLine2))
                                    ]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.invoice.DST_Addr_3))
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "invoice__recipient-contact" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "flex items-center" },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "MailIcon",
                                            svgClasses: "h-4 w-4"
                                          }
                                        }),
                                        _c("span", { staticClass: "ml-2" }, [
                                          _vm._v(
                                            _vm._s(_vm.invoice.DST_email_addr)
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "flex items-center" },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "PhoneIcon",
                                            svgClasses: "h-4 w-4"
                                          }
                                        }),
                                        _c("span", { staticClass: "ml-2" }, [
                                          _vm._v(
                                            _vm._s(_vm.invoice.DST_Home_Phone)
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col w-full md:w-1/2 mt-base text-right mt-12"
                              },
                              [
                                _c("h5", [
                                  _c("strong", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t(_vm.resources.ShipTo.i18n) ||
                                            _vm.resources.ShipTo.name
                                        ) +
                                        ":\n                  "
                                    )
                                  ])
                                ]),
                                _c("h5", [
                                  _vm._v(_vm._s(_vm.invoice.ship_to_name))
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "invoice__company-info my-4" },
                                  [
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.invoice.ship_to_addr_1))
                                    ]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.invoice.ship_to_addr_3))
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.invoice.ship_to_city) +
                                          _vm._s(
                                            " (Postal code:" +
                                              _vm.invoice.ship_to_zip +
                                              ")"
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                    _c("p")
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "invoice__company-contact" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "flex items-center justify-end"
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "MailIcon",
                                            svgClasses: "h-4 w-4"
                                          }
                                        }),
                                        _c("span", { staticClass: "ml-2" }, [
                                          _vm._v(
                                            _vm._s(_vm.invoice.ship_to_email)
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "flex items-center justify-end"
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "PhoneIcon",
                                            svgClasses: "h-4 w-4"
                                          }
                                        }),
                                        _c("span", { staticClass: "ml-2" }, [
                                          _vm._v(
                                            _vm._s(_vm.invoice.ship_to_phone)
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        !_vm.invoice
                          ? _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.resources.NoAvailableData.i18n
                                    ) || _vm.resources.NoAvailableData.name
                                  ) +
                                  "\n            "
                              )
                            ])
                          : _c(
                              "div",
                              { staticClass: "p-base" },
                              [
                                _c(
                                  "vs-table",
                                  {
                                    attrs: { hoverFlat: "", data: _vm.invoice },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var data = ref.data
                                            return [
                                              _vm._l(data.odl, function(
                                                tr,
                                                indextr
                                              ) {
                                                return _c(
                                                  "vs-tr",
                                                  { key: indextr },
                                                  [
                                                    _c("vs-td", [
                                                      _vm._v(
                                                        _vm._s(indextr + 1)
                                                      )
                                                    ]),
                                                    _c(
                                                      "vs-td",
                                                      {
                                                        attrs: {
                                                          data:
                                                            data.odl[indextr]
                                                              .item_code
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              data.odl[indextr]
                                                                .item_code
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "vs-td",
                                                      {
                                                        attrs: {
                                                          data:
                                                            data.odl[indextr]
                                                              .qty_shipped
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              data.odl[indextr]
                                                                .qty_shipped
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "vs-td",
                                                      {
                                                        attrs: {
                                                          data:
                                                            data.odl[indextr]
                                                              .qty_ordered
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              data.odl[indextr]
                                                                .qty_ordered
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "vs-td",
                                                      {
                                                        attrs: {
                                                          data:
                                                            data.odl[indextr]
                                                              .item_name_1
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              data.odl[indextr]
                                                                .item_name_1
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "vs-td",
                                                      {
                                                        attrs: {
                                                          data:
                                                            data.odl[indextr]
                                                              .price_2
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              data.odl[indextr]
                                                                .price_2
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "vs-td",
                                                      {
                                                        attrs: {
                                                          data: data.pv_amount
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(data.pv_amount)
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "vs-td",
                                                      {
                                                        attrs: {
                                                          data:
                                                            data.odl[indextr]
                                                              .sales_price
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              data.odl[indextr]
                                                                .sales_price
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "vs-td",
                                                      {
                                                        attrs: {
                                                          data:
                                                            data.sales_amount
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              data.sales_amount
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              }),
                                              _c(
                                                "vs-tr",
                                                [
                                                  _c("vs-td"),
                                                  _c("vs-td"),
                                                  _c("vs-td"),
                                                  _c("vs-td"),
                                                  _c("vs-td"),
                                                  _c("th", [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            _vm.resources
                                                              .TotalVolumen.i18n
                                                          ) ||
                                                            _vm.resources
                                                              .TotalVolumen.name
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.invoice.pv_amount
                                                      )
                                                    )
                                                  ]),
                                                  _c("th", [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            _vm.resources
                                                              .Subtotal.i18n
                                                          ) ||
                                                            _vm.resources
                                                              .Subtotal.name
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.invoice.sales_amount
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      483632677
                                    )
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "thead" },
                                      [
                                        _c("vs-th", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.Item.i18n
                                                ) || _vm.resources.Item.name
                                              ) +
                                              "\n                  "
                                          )
                                        ]),
                                        _c("vs-th", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.Code.i18n
                                                ) || _vm.resources.Code.name
                                              ) +
                                              "\n                  "
                                          )
                                        ]),
                                        _c("vs-th", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.QtyShip.i18n
                                                ) || _vm.resources.QtyShip.name
                                              ) +
                                              "\n                  "
                                          )
                                        ]),
                                        _c("vs-th", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.QtyOrder.i18n
                                                ) || _vm.resources.QtyOrder.name
                                              ) +
                                              "\n                  "
                                          )
                                        ]),
                                        _c("vs-th", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.Description.i18n
                                                ) ||
                                                  _vm.resources.Description.name
                                              ) +
                                              "\n                  "
                                          )
                                        ]),
                                        _c("vs-th", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.Volumen.i18n
                                                ) || _vm.resources.Volumen.name
                                              ) +
                                              "\n                  "
                                          )
                                        ]),
                                        _c("vs-th", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.TotalVolumen
                                                    .i18n
                                                ) ||
                                                  _vm.resources.TotalVolumen
                                                    .name
                                              ) +
                                              "\n                  "
                                          )
                                        ]),
                                        _c("vs-th", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.Price.i18n
                                                ) || _vm.resources.Price.name
                                              ) +
                                              "\n                  "
                                          )
                                        ]),
                                        _c("vs-th", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.Total.i18n
                                                ) || _vm.resources.Total.name
                                              ) +
                                              "\n                  "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                ),
                                _c("div", { staticClass: "vx-row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vx-col w-full lg:w-1/2 mb-base p-base"
                                    },
                                    [
                                      _c("h6", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$t(
                                                _vm.resources.Comments.i18n
                                              ) || _vm.resources.Comments.name
                                            ) +
                                            ":\n                  "
                                        )
                                      ]),
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.invoice.comment_1))
                                      ]),
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.invoice.comment_2))
                                      ]),
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.invoice.comment_3))
                                      ]),
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.invoice.comment_4))
                                      ]),
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.invoice.comment_5))
                                      ]),
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.invoice.comment_6))
                                      ]),
                                      _c("br"),
                                      _c("h6", [
                                        _c("strong", [_vm._v("Warehouse 2")])
                                      ]),
                                      _c("p", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(_vm.invoice.ord_whs_nm1)
                                          )
                                        ])
                                      ]),
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.invoice.ord_whs_adr1))
                                      ]),
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.invoice.ord_whs_adr2))
                                      ]),
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.invoice.ord_whs_adr3))
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vx-col w-full lg:w-1/2 mb-base p-base"
                                    },
                                    [
                                      _c(
                                        "table",
                                        {
                                          staticClass: "w-3/4 ml-auto mt-3",
                                          attrs: { hoverFlat: "" }
                                        },
                                        [
                                          _c("vs-tr", [
                                            _c("th", [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Tax.i18n
                                                    ) || _vm.resources.Tax.name
                                                  ) +
                                                  " (" +
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources.Taxable.i18n
                                                    ) ||
                                                      _vm.resources.Taxable.name
                                                  ) +
                                                  "\n                        0.00 @ " +
                                                  _vm._s(
                                                    _vm.invoice.tax_percent
                                                  ) +
                                                  "%)\n                      "
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.invoice
                                                    .total_taxable_amount
                                                )
                                              )
                                            ])
                                          ]),
                                          _c("vs-tr", [
                                            _c("th", [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .ShippingAndHandling
                                                        .i18n
                                                    ) ||
                                                      _vm.resources
                                                        .ShippingAndHandling
                                                        .name
                                                  ) +
                                                  "\n                        (" +
                                                  _vm._s(
                                                    _vm.invoice.ord_shv_nm
                                                  ) +
                                                  ")\n                      "
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.invoice.total_invoice_amt
                                                )
                                              )
                                            ])
                                          ]),
                                          _c("vs-tr", [
                                            _c("th", [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .TotalAmountDue.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .TotalAmountDue.name
                                                  ) +
                                                  "\n                      "
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(_vm.invoice.sales_amount)
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            ),
                        _c(
                          "div",
                          { staticClass: "invoice__footer text-right p-base" },
                          [
                            _c("h5", [
                              _c("strong", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        _vm.resources.PaymentsReceived.i18n
                                      ) || _vm.resources.PaymentsReceived.name
                                    ) +
                                    "\n                "
                                )
                              ])
                            ]),
                            _c("p", [
                              _c("strong", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        _vm.resources.ReceiptAmount.i18n
                                      ) || _vm.resources.ReceiptAmount.name
                                    ) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.invoice.amount_received) +
                                  "\n              "
                              )
                            ]),
                            _c("p", [
                              _c("strong", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        _vm.resources.TotalCashReceipts.i18n
                                      ) || _vm.resources.TotalCashReceipts.name
                                    ) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.invoice.sales_amount) +
                                  "\n              "
                              )
                            ]),
                            _c("p", [
                              _c("strong", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        _vm.resources.InvoceBalance.i18n
                                      ) || _vm.resources.InvoceBalance.name
                                    ) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.invoice.invoice_balance) +
                                  "\n              "
                              )
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "vx-row" }, [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full text-right" },
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: {
                                    color: "rgb(174,174,174)",
                                    type: "filled"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.popupActive = false
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(_vm.resources.Close.i18n) ||
                                          _vm.resources.Close.name
                                      ) +
                                      "\n                "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _c(
            "div",
            [
              _c("vx-card", [
                _c("h4", { staticClass: "text-center text-danger mb-2" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t(_vm.alerts.MsgInfotraxOffline.i18n) ||
                          _vm.alerts.MsgInfotraxOffline.i18n
                      ) +
                      ".\n      "
                  )
                ])
              ])
            ],
            1
          ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t(_vm.resources.News.i18n) || _vm.resources.News.i18n,
            active: _vm.enabledPopup
          },
          on: {
            "update:active": function($event) {
              _vm.enabledPopup = $event
            }
          }
        },
        [
          _c("a", { attrs: { href: _vm.urlPopup } }, [
            _c("img", {
              attrs: {
                src: _vm.imgPopup,
                alt: "graphic",
                width: "550",
                heigth: "500"
              }
            })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }