<template>
  <div>
    <vx-card
      class="mb-2"
      :title="$t(resources.WeeksHonors.i18n) || resources.WeeksHonors.name"
    >
      <div class="carousel-example">
        <swiper :options="swiperOption">
          <swiper-slide v-for="item in honorsList" :key="item">
            <img class="responsive" :src="item" width="550" heigth="500" />
          </swiper-slide>
          <div
            class="swiper-button-prev swiper-button-white"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-white"
            slot="button-next"
          ></div>
        </swiper>
      </div>
    </vx-card>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  props: {
    honorsList: Array
  },
  data() {
    return {
      resources: resources,
      swiperOption: {
        autoplay: {
          delay: 2500
        },
        slidesPerView: 2,
        spaceBetween: 25,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  components: {
    swiper,
    swiperSlide
  }
};
</script>
